

























import { Component, Watch } from 'vue-property-decorator'
import Slide from '@/mixins/Slide.ts'
import { mixins } from 'vue-class-component'
import MediaContent from '@/components/MediaContent.vue'
import KeyFigures from '@/components/KeyFigures.vue'
import Color from '@/constants/color'
import ArrowDownNextSlide from '@/components/ArrowDownNextSlide.vue'
import ScrollerElement, { ScrollerElementAnimationEvent } from '@/components/ScrollerElement.vue'
import CheatSheet from '@/components/CheatSheet.vue'

@Component({
    components: {
        ScrollerElement,
        MediaContent,
        KeyFigures,
        ArrowDownNextSlide,
        CheatSheet
    }
})
export default class SlideFloatingImages extends mixins(Slide) {
    $refs!: {
        title: HTMLElement
    }

    get walkerItem() {
        return this.walker.item as SlideWalkerItemFloatingImages
    }

    get classNames() {
        const classNames = [this.$style.root, 'color--' + this.color]

        if (this.walkerItem.invertContrast) {
            classNames.push(this.$style['root--inverted-contrast'])
        }

        return classNames
    }

    populateAppear(timeline: GSAPTimeline) {
        if (this.$refs.title) {
            timeline.fromTo(
                this.$refs.title,
                {
                    opacity: 0,
                    y: 60
                },
                {
                    opacity: 1,
                    y: 0,
                    duration: 1.2,
                    ease: 'power3'
                },
                0.8
            )
        }
    }

    populateEnterY({ timeline }: ScrollerElementAnimationEvent) {
        if (this.$refs.title) {
            timeline.fromTo(
                this.$refs.title,
                {
                    opacity: 0,
                    y: 60
                },
                {
                    opacity: 1,
                    y: 0,
                    duration: 0.5,
                    ease: 'none'
                },
                0.5
            )
        }
    }

    @Watch('walker', { immediate: true })
    onWalkerChange() {
        this.color = (this.walker.item as SlideWalkerItemFloatingImages).invertContrast ? Color.DARK : Color.LIGHT
    }
}
